.list {
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  font-family: 'Courier New', monospace;
}

.item {
  border: 2px solid #000000;
  border-radius: 1rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.image {
  flex: 0 0 100px;
  padding: 1rem;
  box-sizing: border-box;
}

.image img {
  display: block;
  width: 100%;
  height: 90px;
  object-fit: contain;
}

.content {
  flex: 1;
  width: calc(100% - 250px);
  padding: 1rem;
  font-size: 1.3rem;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.content .description {
  width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.link {
  flex: 0 0 150px;
  padding: 1rem;
  text-align: center;
  box-sizing: border-box;
}

.link a {
  font-size: 90px;
  line-height: 1em;
  text-decoration: none;
  color: #000;
}

@media (max-width: 600px) {
  .image, .content, .link {
    flex: 100%;
    width: 100%;
  }
  .image img {
    height: 200px;
  }
  .link {
    text-align: center;
  }
}
.container {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container ul {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
}

.prev, .next, .element {
  padding: 0.15rem 0.8rem;
  border: 1px solid #000;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.element {
  border-left: none;
  font-size: 1.2rem;
}

.prev, .next {
  font-size: 1.5rem;
}

.prev {
  padding-left: 1rem;
  border-radius: 8px 0 0 8px;
}

.next {
  padding-right: 1rem;
  border-left: 0;
  border-radius: 0 8px 8px 0;
}


@media (max-width: 600px) {
  ul {
    flex-wrap: wrap;
    gap: 0.2em;
  }

  .element, .next {
    border-left: 1px solid #000;
  }
}
.container {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  max-width: 900px;
  margin: 0 auto;
  padding: 1rem;
  padding-top: 3rem;
  gap: 1rem;
  box-sizing: border-box;
  position: relative;
  font-family: 'Courier New', monospace;
  font-size: 1.3rem;
}

.image {
  flex: 0 1 25%;
  padding: 1rem;
}

.image img {
  display: block;
  width: auto;
  height: auto;
  max-height: 25rem;
  max-width: 100%;
  object-fit: contain;
  margin: auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
}

.content .name {
  margin: 0;
}

.content .description p {
  margin-top: 0;
  margin-bottom: 0.5rem;
}

.link a {
  text-decoration: none;
  color: #000;
  font-size: 1rem;
}

@media (max-width: 600px) {
  .image, .content {
    flex: 1 1 100%;
    width: 100%;
  }
}
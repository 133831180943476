.random {
  border: 2px solid #000;
  border-radius: 0.5rem;
}

.random a {
  text-decoration: none;
  color: #000;
  font-size: 1.2rem;
  padding: 0.3rem 2rem;
}
.form input {
  padding: 0.3rem 1.5rem;
  font-size: 1.2rem;
  width: 8rem;
  border-color: #000;
  border-radius: 1rem;
}

.form input:focus {
  outline: none;
  border-width: 3px;
}